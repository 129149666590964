import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { CloudUpload } from "@mui/icons-material";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFolderOpen,
  faHammer,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import DataFetcher from "../../utils/DataFetcher";
import { FETCH_RETRIES } from "../PromptDashboard/utils/constants";
import InputVariablesFields from "./components/InputVariablesFields";
import { Auth } from "aws-amplify";

const AssistantDetails = ({
  selectedPrompt,
  applicantId,
  agentDeleted,
  onUploadFile,
  onCreateOrUpdate,
  onDelete,
  onSelectAgent,
  toggleFileList,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dataFetcher = DataFetcher();
  const [agents, setAgents] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [variables, setVariables] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [assistantName, setAssistantName] = useState("");
  const [selectedAgent, setSelectedAgent] = useState({});
  const [agentsLoaded, setAgentsLoaded] = useState(false);
  const [customerFromClaim, setCustomer] = useState(false);
  const [promptsLoaded, setPromptsLoaded] = useState(false);
  const [displayFields, setDisplayFields] = useState(false);
  const [loadingAgents, setLoadingAgents] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [loadingPrompts, setLoadingPrompts] = useState(false);
  const [openAgentsList, setOpenAgentsList] = useState(false);
  const [openPromptsList, setOpenPromptsList] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState(selectedPrompt ?? null);
  const [instructions, setInstructions] = useState(
    selectedPrompt ? selectedPrompt.human_instruction : ""
  );

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    const clearFields = async () => {
      if (agentDeleted === "Yes") {
        await fetchAgents(applicantId);
        setAssistantName("");
        setInstructions("");
        setSelectedAgent({});
      }
      setLoadingAction(false);
    };

    clearFields();
  }, [applicantId, agentDeleted]);

  useEffect(() => {
    const checkCustomer = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        const customer = userInfo.attributes["custom:account"];
        setCustomer(customer);
      } catch (e) {
        console.log("No current user", e);
      }
    };

    checkCustomer();
  }, []);

  useEffect(() => {
    parseVariables(instructions);
  }, [instructions]);

  const fetchAgents = async (applicantId) => {
    setLoadingAgents(true);
    try {
      const fetchedAgents = await dataFetcher.fetchAgents(
        applicantId,
        customerFromClaim
      );
      setAgents(fetchedAgents);
      setLoadingAgents(false);
      setAgentsLoaded(true);
    } catch (error) {
      console.log("Error fetching agents:", error);
      setLoadingAgents(false);
      throw new Error("Error fetching agents:", error);
    }
  };

  const fetchPrompts = async () => {
    setLoadingPrompts(true);
    try {
      const fetchedPrompts = await dataFetcher.fetchPrompts(applicantId);
      setPrompts(fetchedPrompts);
      setLoadingPrompts(false);
      setPromptsLoaded(true);
    } catch (error) {
      console.log("Error fetching prompts:", error);
      setLoadingPrompts(false);
      setPrompts([]);
    }
  };

  const parseVariables = (str) => {
    const matches = [...str.matchAll(/\{(.*?)\}/g)];

    const parsedVariables = matches.map((match) => ({
      name: match[1],
      value: "",
    }));

    setVariables(parsedVariables);
  };

  const replaceVariablesInString = (promptInstructions) => {
    let resultString = promptInstructions;

    variables.forEach(({ name, value }) => {
      const regex = new RegExp(`\\{${name}\\}`, "g");

      resultString = resultString.replace(regex, value);
    });

    return resultString;
  };

  const onDrop = (acceptedFiles) => {
    setErrorMessage("");
    onUploadFile(acceptedFiles);
  };

  const onDropRejected = (rejectedFiles) => {
    const unsupportedFile = rejectedFiles[0];
    if (unsupportedFile) {
      setErrorMessage(
        `File ${unsupportedFile.file.name} is not a supported format. Only DOCX or PDF files.`
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
    },
  });

  const handleSelectAgent = (agent) => {
    onSelectAgent(agent);
    setOpenAgentsList(false);
    setSelectedAgent(agent);
    setAssistantName(agent.name || "");
    setInstructions(currentPrompt?.human_instruction || agent.prompt || "");
  };

  const handleNewAgent = () => {
    setDisplayFields(true);
    setAssistantName("");
    setInstructions("");
    setSelectedAgent({});
    setCurrentPrompt(null);
  };

  const handleCreateOrUpdate = async () => {
    setLoadingAction(true);
    const data = {
      instructions:
        variables.length > 0
          ? replaceVariablesInString(instructions)
          : instructions,
      name: assistantName,
      selectedAgentId: selectedAgent.id ?? "",
    };
    await onCreateOrUpdate(data);
    await fetchAgents(applicantId);
    setLoadingAction(false);
  };

  const handleDelete = async () => {
    setLoadingAction(true);
    await onDelete();
  };

  const handleSelectPrompt = (prompt) => {
    setCurrentPrompt(prompt);
    setOpenPromptsList(false);
    setInstructions(prompt?.human_instruction);
  };

  const handleUpdateVariables = (index, updatingName, value) => {
    setVariables((prev) => {
      const updatedVariables = [...prev];
      const updatedVariable = { ...updatedVariables[index] };

      if (updatingName) {
        updatedVariable.name = value;
      } else {
        updatedVariable.value = value;
      }
      updatedVariables[index] = updatedVariable;

      return updatedVariables;
    });
  };

  return (
    <Box
      gap={2}
      width={isMobileOrTablet ? "100%" : "20%"}
      padding={2}
      display="flex"
      borderRight={1}
      borderColor="divider"
      flexDirection="column"
      sx={{ backgroundColor: "#f9f9f9" }}
    >
      <FormControl fullWidth sx={{ marginTop: "6px" }}>
        <InputLabel sx={{ fontSize: "13px", margin: "-5px", fontWeight: 700 }}>
          Select Assistant
        </InputLabel>
        <Select
          displayEmpty
          open={openAgentsList}
          value={selectedAgent?.name || ""}
          onOpen={() => {
            if (!agentsLoaded) {
              fetchAgents(applicantId);
            }
            setOpenAgentsList(true);
          }}
          onClose={() => setOpenAgentsList(false)}
          renderValue={(selected) => {
            return selected || "";
          }}
          sx={{
            height: "65%",
            "& .MuiSelect-select": {
              fontSize: "13px",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                minWidth: "100%",
                maxWidth: "100%",
                maxHeight: "200px",
              },
            },
          }}
        >
          {loadingAgents ? (
            <MenuItem disabled>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <CircularProgress size={24} />
              </Box>
            </MenuItem>
          ) : agents?.length > 0 ? (
            <Scrollbars style={{ height: "180px", width: "100%" }}>
              {agents.map((agent) => (
                <MenuItem
                  key={agent.id}
                  value={agent.name}
                  sx={{
                    fontSize: "12px",
                    maxWidth: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  onClick={() => handleSelectAgent(agent)}
                >
                  {agent.name}
                </MenuItem>
              ))}
            </Scrollbars>
          ) : (
            <MenuItem disabled>No assistants found</MenuItem>
          )}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        onClick={handleNewAgent}
        sx={{
          color: "white",
          marginTop: "-20px",
          alignSelf: "center",
          marginBottom: "-2px",
          textTransform: "none",
          backgroundColor: "#015d86",
          "&:hover": { backgroundColor: "#014a6d" },
          "& .MuiButton-startIcon>*:nth-of-type(1)": {
            fontSize: "14px !important",
          },
        }}
        startIcon={<Box>&#128640;</Box>}
      >
        <Typography sx={{ fontSize: "13px" }}>New Agent</Typography>
      </Button>
      {(selectedPrompt !== null ||
        displayFields ||
        Object.keys(selectedAgent).length > 0) && (
        <>
          <Typography
            variant="subtitle1"
            fontWeight={600}
            sx={{
              fontSize: "14px",
              marginTop: "-10px",
              marginBottom: "-12px",
            }}
          >
            Name:
          </Typography>
          <TextField
            value={assistantName}
            onChange={(e) => setAssistantName(e.target.value)}
            fullWidth
            InputProps={{
              sx: {
                height: "60%",
                fontSize: "14px",
              },
            }}
            sx={{
              marginBottom: "-10px",
            }}
          />
          <FormControl fullWidth sx={{ marginTop: "16px" }}>
            <InputLabel
              sx={{
                margin: "-5px",
                fontWeight: 700,
                fontSize: "13px",
                marginTop: "-18px",
              }}
            >
              Select Prompt
            </InputLabel>
            <Select
              displayEmpty
              open={openPromptsList}
              value={currentPrompt?.prompt_name || ""}
              onOpen={() => {
                if (!promptsLoaded) {
                  fetchPrompts();
                }
                setOpenPromptsList(true);
              }}
              onClose={() => setOpenPromptsList(false)}
              renderValue={(selected) => {
                return selected || "";
              }}
              sx={{
                height: "65%",
                marginTop: "-10px",
                "& .MuiSelect-select": {
                  fontSize: "13px",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    minWidth: "100%",
                    maxWidth: "100%",
                    overflow: "hidden",
                    maxHeight: "155px",
                  },
                },
              }}
            >
              {loadingPrompts ? (
                <MenuItem disabled>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress size={24} />
                  </Box>
                </MenuItem>
              ) : prompts?.length > 0 ? (
                <Scrollbars style={{ height: "140px", width: "100%" }}>
                  {prompts.map((prompt) => (
                    <MenuItem
                      key={prompt.prompt_name}
                      value={prompt.prompt_name}
                      sx={{
                        fontSize: "12px",
                        maxWidth: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      onClick={() => handleSelectPrompt(prompt)}
                    >
                      {prompt.prompt_name}
                    </MenuItem>
                  ))}
                </Scrollbars>
              ) : (
                <MenuItem disabled>No prompts available</MenuItem>
              )}
            </Select>
          </FormControl>
          <Typography
            variant="subtitle1"
            fontWeight={600}
            sx={{
              display: "flex",
              fontSize: "14px",
              marginTop: "-14px",
              marginBottom: "-12px",
              justifyContent: "space-between",
            }}
          >
            Instructions:
            {currentPrompt && (
              <Button
                variant="contained"
                color="success"
                startIcon={<FontAwesomeIcon icon={faHammer} />}
                sx={{
                  height: "30px",
                  minWidth: "30px",
                  textTransform: "none",
                }}
                onClick={() => {
                  navigate("/prompts", { state: { currentPrompt } });
                }}
              >
                Customize
              </Button>
            )}
          </Typography>
          <TextField
            rows={4}
            fullWidth
            multiline
            value={instructions}
            InputProps={{
              sx: { fontSize: "14px", marginTop: "2px" },
            }}
            onChange={(e) => {
              setCurrentPrompt(null);
              setInstructions(e.target.value);
            }}
          />
          {currentPrompt && variables.length > 0 && (
            <InputVariablesFields
              currentPrompt={currentPrompt}
              variables={variables}
              onUpdateVariable={handleUpdateVariables}
            />
          )}
          <Button
            color="secondary"
            variant="contained"
            onClick={toggleFileList}
            sx={{
              mt: "5px",
              alignSelf: "center",
              textTransform: "none",
            }}
            startIcon={
              <FontAwesomeIcon
                icon={faFolderOpen}
                style={{ fontSize: "12px" }}
              />
            }
          >
            <Typography sx={{ fontSize: "13px" }}>Document list</Typography>
          </Button>
          <Box
            {...getRootProps()}
            sx={{
              padding: 2,
              width: "100%",
              height: "80px",
              cursor: "pointer",
              textAlign: "center",
              borderRadius: "4px",
              marginTop: "10px",
              border: "2px dashed #ccc",
              backgroundColor: "#fafafa",
            }}
          >
            <IconButton
              sx={{
                marginTop: "-20px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <CloudUpload />
            </IconButton>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography variant="body1">Drop the files here...</Typography>
            ) : (
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", marginTop: "-5px" }}
              >
                Click or drag file to this area to upload
              </Typography>
            )}
          </Box>
          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: 2, fontSize: "12px" }}>
              {errorMessage}
            </Alert>
          )}
          <Box
            sx={{
              height: "90%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginTop: isMobileOrTablet ? "30px" : "40px",
              marginBottom: isMobileOrTablet ? "5px" : "0px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobileOrTablet ? "column" : "row", // Vertical en móvil, horizontal en desktop
                gap: 2, // Espacio entre botones
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={handleCreateOrUpdate}
                disabled={loadingAction}
                sx={{
                  width: "100%",
                  color: "white",
                  marginTop: "-30px",
                  alignSelf: "center",
                  textTransform: "none",
                  backgroundColor: "#015d86",
                  "&:hover": { backgroundColor: "#014a6d" },
                }}
                startIcon={
                  loadingAction ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ fontSize: "12px" }}
                    />
                  )
                }
              >
                <Typography sx={{ fontSize: "12px" }}>
                  {loadingAction
                    ? "Saving..."
                    : Object.keys(selectedAgent).length > 0
                    ? "Save"
                    : "Create agent"}
                </Typography>
              </Button>
              {Object.keys(selectedAgent).length > 0 && (
                <Button
                  variant="contained"
                  onClick={handleDelete}
                  disabled={loadingAction}
                  sx={{
                    width: "100%",
                    color: "white",
                    marginTop: "-30px",
                    marginLeft: "40px",
                    alignSelf: "center",
                    textTransform: "none",
                    backgroundColor: "#D2122E",
                    "&:hover": { backgroundColor: "#b20000" },
                  }}
                  startIcon={
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ fontSize: "12px" }}
                    />
                  }
                >
                  <Typography sx={{ fontSize: "12px" }}>Delete</Typography>
                </Button>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AssistantDetails;
