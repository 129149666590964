import _ from "lodash";
import { API, Auth } from "aws-amplify";
import {
  getAgent,
  getFiles,
  getLangchainProgramConversation,
  getPrompt,
  putFiles,
} from "../graphql/queries";

const ENV = _.isUndefined(process.env.REACT_APP_USER_BRANCH)
  ? "dev"
  : process.env.REACT_APP_USER_BRANCH;

const handleError = (error, fallbackValue, errorMessage) => {
  console.error(errorMessage, error);
  // You could also use a centralized error logging service here
  return fallbackValue;
};

const DataFetcher = () => {
  const fetchAgents = async (applicantId, customer_id) => {
    const params = {
      customer_id: customer_id,
      domain: applicantId,
      provider: "",
      group: false,
    };
    const response = await API.graphql({
      query: getAgent,
      variables: params,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    const fetchAgents = JSON.parse(response.data.getAgent.body);
    console.log("fetchAgents: ", fetchAgents);
    return fetchAgents;
  };
  const fetchUserId = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user;
    } catch (error) {
      throw new Error("Error fetching user ID:", error);
    }
  };
  const fetchFiles = async (applicantId) => {
    try {
      const response = await API.graphql({
        query: getFiles,
        variables: { applicant_id: applicantId },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const fetchedFiles = JSON.parse(response.data.getFiles.body);
      console.log("Fetched files:", fetchedFiles);
      return fetchedFiles;
    } catch (error) {
      throw new Error("Error fetching files:", error);
    }
  };

  const fetchPrompts = async (userId) => {
    try {
      const params = {
        user_id: userId,
        id: "",
      };
      const response = await API.graphql({
        query: getPrompt,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const responseBody = JSON.parse(response.data.getPrompt.body);
      return responseBody;
    } catch (error) {
      return handleError(error, [], "Error fetching prompts:");
    }
  };

  const saveFilesToDatabase = async (
    uploadedFiles,
    applicantId,
    customer_id
  ) => {
    try {
      console.log("uploadedFiles: ", uploadedFiles);
      console.log("applicantId: ", applicantId);
      const response = await API.graphql({
        query: putFiles,
        variables: {
          user_id: applicantId,
          customer: customer_id,
          s3_bucket: `${ENV.toLowerCase()}-cai3p0-integrations`,
          files: uploadedFiles,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("Response from putFiles:", response);
    } catch (error) {
      console.error("Error saving files to database:", error);
    }
  };
  const fetchLangChainAssistantData = async (
    params,
    retries,
    isFirstAttempt
  ) => {
    try {
      const response = await API.graphql({
        query: getLangchainProgramConversation,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const responseBody = response.data.getLangchainProgramConversation.body;
      const start = responseBody.indexOf("answer=") + "answer=".length;
      const end = responseBody.lastIndexOf("}");
      const parsedResponse = responseBody.substring(start, end).trim();
      if (parsedResponse) {
        return parsedResponse;
      } else {
        throw new Error("No response");
      }
    } catch (error) {
      if (retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        return fetchLangChainAssistantData(params, retries - 1, false);
      } else {
        return isFirstAttempt
          ? "I am out of the office I will be back soon"
          : "Unable to process request.";
      }
    }
  };
  return {
    fetchAgents,
    fetchUserId,
    fetchFiles,
    fetchPrompts,
    saveFilesToDatabase,
    fetchLangChainAssistantData,
  };
};
export default DataFetcher;
